import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'tasks',
    loadChildren: () => import('./tasks/tasks.module').then(m => m.TasksPageModule)
  },
  {
    path: 'billing',
    loadChildren: () => import('./billing/billing.module').then(m => m.BillingPageModule)
  },
  {
    path: 'bookings',
    loadChildren: () => import('./bookings/bookings.module').then(m => m.BookingsPageModule)
  },
  {
    path: 'bookings/:loc/:date',
    loadChildren: () => import('./bookings/bookings.module').then(m => m.BookingsPageModule)
  },
  {
    path: 'schedules',
    loadChildren: () => import('./schedules/schedules.module').then(m => m.SchedulesPageModule)
  },
  {
    path: 'schedules/:loc/:date',
    loadChildren: () => import('./schedules/schedules.module').then(m => m.SchedulesPageModule)
  },
  {
    path: 'templates',
    loadChildren: () => import('./templates/templates.module').then(m => m.TemplatesPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersPageModule)
  },
  {
    path: 'patients',
    loadChildren: () => import('./patients/patients.module').then(m => m.PatientsPageModule)
  },
  {
    path: 'patients/:id',
    loadChildren: () => import('./patients/patients.module').then(m => m.PatientsPageModule)
  },
  {
    path: 'inventory',
    loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryPageModule)
  },
  {
    path: 'locations',
    loadChildren: () => import('./locations/locations.module').then(m => m.LocationsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'exams',
    loadChildren: () => import('./exams/exams.module').then( m => m.ExamsPageModule)
  },
  {
    path: 'view-media/:id',
    loadChildren: () => import('./view-media/view-media.module').then( m => m.ViewMediaPageModule)
  },
  {
    path: 'templates-entry/:id/:patient',
    loadChildren: () => import('./templates-entry/templates-entry.module').then( m => m.TemplatesEntryPageModule)
  },
  {
    path: 'templates-entry/:id',
    loadChildren: () => import('./templates-entry/templates-entry.module').then( m => m.TemplatesEntryPageModule)
  },
  {
    path: 'payments',
    loadChildren: () => import('./payments/payments.module').then( m => m.PaymentsPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'inventory-categories',
    loadChildren: () => import('./inventory-categories/inventory-categories.module').then( m => m.InventoryCategoriesPageModule)
  },
  {
    path: 'inventory-ledger',
    loadChildren: () => import('./inventory-ledger/inventory-ledger.module').then( m => m.InventoryLedgerPageModule)
  },
  {
    path: 'inventory-ledger/:id',
    loadChildren: () => import('./inventory-ledger/inventory-ledger.module').then( m => m.InventoryLedgerPageModule)
  },
  {
    path: 'inventory-scans',
    loadChildren: () => import('./inventory-scans/inventory-scans.module').then( m => m.InventoryScansPageModule)
  },
  {
    path: 'billing-terminal',
    loadChildren: () => import('./billing-terminal/billing-terminal.module').then( m => m.BillingTerminalPageModule)
  },
  {
    path: 'billing-terminal/:id',
    loadChildren: () => import('./billing-terminal/billing-terminal.module').then( m => m.BillingTerminalPageModule)
  },
  {
    path: 'billing-terminal/:id/:patient',
    loadChildren: () => import('./billing-terminal/billing-terminal.module').then( m => m.BillingTerminalPageModule)
  },
  {
    path: 'print-preview/:type/:id',
    loadChildren: () => import('./print-preview/print-preview.module').then( m => m.PrintPreviewPageModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then( m => m.ReportsPageModule)
  },
  {
    path: 'reconciliation',
    loadChildren: () => import('./reconciliation/reconciliation.module').then( m => m.ReconciliationPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
