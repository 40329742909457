import { Component } from '@angular/core';

import { AppService } from './services/app.service';
//import { FCM } from '@ionic-native/fcm/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    {
         title: 'Home',
         url: '/home',
         icon: 'home'
   },
   {
         title: 'Patients',
         url: '/patients',
         icon: 'people'
     },
     {
         title: 'Bookings',
         url: '/bookings',
         icon: 'bookmarks'
     },
     {
         title: 'Schedules',
         url: '/schedules',
         icon: 'calendar'
     },
     {
         title: 'Users',
         url: '/users',
         icon: 'person'
     },
     {
         title: 'Templates',
         url: '/templates',
         icon: 'document'
     },
     {
         title: 'Tests & Lenses',
         url: '/exams',
         icon: 'eye'
     },
     {
         title: 'Locations',
         url: '/locations',
         icon: 'pin'
     },
     {
         title: 'Inventory',
         url: '/inventory',
         icon: 'pricetag'
     },
     {
         title: 'Inventory Scans',
         url: '/inventory-scans',
         icon: 'barcode'
     },
     {
        title: 'Reports & Import',
        url: '/reports',
        icon: 'analytics'
     },
     {
        title: 'Reconciliation',
        url: '/reconciliation',
        icon: 'git-compare'
     },
     {
         title: 'Account',
         url: '/account',
         icon: 'settings'
     },
     {
         title: 'Logout',
         url: '/login',
         icon: 'exit'
     }
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(public app: AppService) {}
}
